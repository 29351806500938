.footerBlock {
  @apply mt-[80px] px-4 py-[90px] pb-[120px] relative flex gap-11 items-center justify-around flex-col sm:flex-row bg-gradient-to-b from-[#ffd200] to-primary font-semibold;
  @apply text-[19px] sm:text-[20px] md:text-[25px] xl:text-[30px] text-center md:text-left;

  &::before, &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 70px;
    background-repeat: repeat-x;
    animation: wave-animation-backwards 10s infinite linear;
    transform: rotateX(180deg);
  }

  &::before {
    top: 0;
    background-image: url("../Assets/Images/roadDividerWhite.jpg");
  }

  &::after {
    bottom: 0;
    background-image: url("../Assets/Images/roadDivider.jpg");
    z-index: 5;
  }
}

@keyframes wave-animation-backwards {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -912px 0;
  }
}

.footerMap {
  @apply relative h-[250px] w-[280px] rounded-[15px] transition-all duration-200 md:h-[250px] md:w-[350px] lg:w-[400px] lg:h-[280px] xl:w-[600px] xl:h-[300px];
}

.CarIcon {
  @apply absolute left-[115px] z-10 w-[100px] transition-all;
  animation: drive 5s linear infinite;
}

@keyframes drive {
  0% {
    transform: rotateZ(0);
    bottom: 0;
  }
  33% {
    transform: rotateZ(-7deg);
  }
  50% {
    transform: rotateZ(0);
    bottom: 15px;
  }
  66% {
    transform: rotateZ(4deg);
  }
  100% {
    transform: rotateZ(0);
    bottom: 0;
  }
}
