.documentationList,
.professionList {
  @apply text-[14px] sm:text-[18px];
}
.documentationList > li,
.professionList > li {
  @apply p-3;
}

.professionListContainer .professionList:first-child > li:nth-child(odd),
.documentationListContainer .documentationList:first-child > li:nth-child(odd),
.professionList:not(:first-child) > li:nth-child(even),
.documentationList:not(:first-child) > li:nth-child(even) {
  @apply bg-[#fecf0031];
}
.professionListContainer .professionList,
.documentationListContainer .documentationList {
  @apply w-1/2 list-inside list-disc text-balance text-[12px] sm:text-[14px] md:text-[16px];
}

.professionListContainer,
.documentationListContainer {
  @apply flex overflow-hidden transition-all duration-1000;
  max-height: 0;
}

.professionListContainer.visible,
.documentationListContainer.visible {
  max-height: 2300px;
}

.documentationList {
  @apply max-h-0 overflow-hidden transition-all duration-1000;
  &.visible {
    @apply max-h-[245px];
  }
}

/* .toggleListButton {
  @apply ml-3 inline-block rounded-2xl bg-primary px-5 shadow-md transition-all duration-200;
} */


.toggleListButton {
  @apply block mx-auto py-1 my-3 overflow-hidden w-[200px] rounded-2xl bg-primary shadow-md transition-all duration-1000;
}

.toggleListButton:hover {
  @apply scale-110 bg-[#ffd000c7];
}
