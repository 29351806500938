p {
  @apply sm:text-[20px] px-3;
}
h3 {
  @apply my-4 text-[18px] leading-relaxed sm:text-[20px] md:text-[28px];
}

/* images look */

.studyTheoryImages {
  @apply relative mx-auto mb-11 flex w-[100%] max-w-[750px] justify-center;
}

.studyTheoryImages :nth-child(1) {
  @apply absolute left-0 top-0 z-[1] w-[35%] rounded-[20%] border-[8px] border-white;
  &.second {
    @apply left-auto right-0;
  }
}

.studyTheoryImages :nth-child(2) {
  @apply relative left-0 top-0 w-[80%] rounded-full border-[8px] border-white pt-[8%];
}

/* images look */

/* list specific  */

.studyList {
  @apply list-disc pl-2;
}
.studyList > li {
  @apply pb-2 pl-2 text-[16px] sm:text-[18px];
}
.studyList > li:last-child {
  @apply max-w-[550px];
}

.studyList > li::marker {
  @apply text-[16px] sm:text-[20px];
}

.studyList > li:nth-child(1)::marker {
  content: "\1F4D8";
}
.studyList > li:nth-child(2)::marker {
  content: "\1FA79";
}
.studyList > li:nth-child(3)::marker {
  content: "\1F46E";
}
.studyList > li:nth-child(4)::marker {
  content: "\1F393";
}

.studyList > li:last-child::marker {
  content: "";
}

.studyList > li:last-child {
  @apply mt-2 text-balance rounded-2xl bg-primary p-3 text-center shadow-md;
}

/* list specific  */

.articleContent {
  @apply mx-auto w-[90%] sm:w-[550px] px-3;
}

.studyArticle {
  @apply mt-12 flex flex-col items-center overflow-hidden lg:flex-row;
}

.studyArticle:not(:first-of-type) {
  @apply mt-[100px] flex-col lg:flex-row-reverse;
}
