.List {
    @apply pr-3 mx-auto flex flex-wrap gap-7 justify-around mt-9;
  }
  
  .ListItem {
      @apply flex flex-col gap-6 w-[150px] sm:w-[267px] transition-all duration-[1.5s];
      transform: rotateY(-90deg);
  }
  
  .showCard {
      transform: rotateY(0);
  }
  
  .card {
      position: relative;
      @apply w-[160px] h-[250px] sm:w-[270px] sm:h-[400px] mx-auto;
      perspective: 1000px;
  }

  @media (min-width: 640px) {
    .card:hover .cardInner {
        transform: rotateY(180deg)
    }
  }

  .cardInner {
      width: 100%;
      height: 100%;
      position: absolute;
      transition: transform 0.5s;
      transform-style: preserve-3d;
  }
  
  
  .card.flipped .cardInner {
      transform: rotateY(180deg)
  }
  
  .cardBack:active .cardInner {
      transform: rotateY(-180deg)
  }
    
  .cardFront, .cardBack {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      background-color: #2c58a7;
      border-radius: 15px;
      border: 2px solid white;
      @apply shadow-md shadow-black;
  }
  
  .turnIcon {
    @apply size-[30px] md:size-[50px] absolute bottom-[2%] right-[5%];
  }

  .cardBack {
      transform:rotateY(180deg);
      @apply flex justify-center items-center text-[12px] sm:text-[20px] text-center text-white  px-2;
  }
  
  .cardImageContainer {
      @apply bg-white w-[80%] mx-auto mt-[20%] p-4;
      border-radius: 15px;
  }
  
  .List h3 {
      line-height: normal;
      @apply text-[18px] sm:text-[25px] text-center  uppercase font-medium;
  }
  
  