.dividerBlock {
  position: relative;
    height: 140px;
    margin-block: 80px;
    background: url("../Assets/Images/continuousDivider.jpg") 0 0 repeat-x;
    width: 100vw;
    animation: wave-animation 10.7s infinite linear;
  }
  
  @keyframes wave-animation {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -912px 0;
    }
  }

 .dividerBlock h2 {
    @apply text-[40px] text-center pt-10 font-bold text-white z-10;
    text-shadow:
    1px 1px 4px black,
    -1px -1px 4px black,
    -1px 1px 4px black;
  }  