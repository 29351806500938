h3 {
  @apply my-4 text-[18px] leading-relaxed sm:text-[20px] md:text-[30px];
}
.reqList {
  @apply list-inside list-decimal;
}

.reqList > li {
  @apply p-3 text-[16px] sm:text-[18px];
}

.reqList > li:nth-child(odd) {
  @apply bg-[#fecf0031];
}
